$light-off-white: #fffcf2;
$light-gray: #ccc5b9;
$med-gray: #403d39;
$dark-gray: #252422;
$orange: #eb5e28;

$smalt-blue-50: #f2f7f7;
$smalt-blue-100: #e6eef0;
$smalt-blue-200: #cfdfe2;
$smalt-blue-300: #acc8cd;
$smalt-blue-400: #86afb6;
$smalt-blue-500: #5a8d96;
$smalt-blue-550: #53838b;
$smalt-blue-600: #456c73;
$smalt-blue-700: #365459;
$smalt-blue-800: #304b50;
$smalt-blue-900: #263c40;
