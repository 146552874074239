@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import 'sass/colors';
@import 'sass/buttons';
@import 'sass/project_section';
@import 'sass/navbar';
@import 'sass/about_section';

$lato-font: 'Lato', sans-serif;

// **** Utilities

* {
  margin: 0;
}

body {
  font-family: $lato-font;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

.section-h1-text {
  font-size: 3rem;
  line-height: 1;
  font-weight: 400;
  color: $light-off-white;
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.break-line {
  width: 100%;
  display: flex;
  justify-items: center;
  align-items: center;
  justify-content: center;
}

.shadow-xl {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
}

// Background Image

.bg-image {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: saturate(60%);
}

// **** Home Landing Screen

.home-landing {
  position: relative;

  .outer-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
  }

  .heading-text {
    font-size: 3rem;
    line-height: 1;
    color: $light-off-white;
    //   font-weight: 700;
    text-align: center;
    @media (min-width: 768px) {
      font-size: 3.75rem;
      line-height: 1;
    }
  }
}

.home-landing::before,
.home-landing::after {
  content: '';
  position: absolute;
  // height: 220px;
  height: 145px;
  bottom: -120px;
  right: 0;
  left: 0;
  // background: $med-gray;
  background: $smalt-blue-800;
  transform: skewY(-2deg);
  -webkit-transform: skewY(-2deg);
  -moz-transform: skewY(-2deg);
  -ms-transform: skewY(-2deg);
}

// **** Posts Screen

.bl-1 {
  height: 4rem;
  background-color: $smalt-blue-800;
}

.post-main {
  background-color: $med-gray;
  min-height: 100vh;

  .container {
    max-width: 72rem;
    margin: 0 auto;
  }

  .post-cards-grid {
    display: grid;
    gap: 2rem;

    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media (min-width: 1024px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  .post-article {
    display: block;
    height: 16rem;
    position: relative;
    border-radius: 0.375rem;
    margin: 1rem;
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    border-radius: 0.375rem;
  }

  .title-span {
    display: flex;
    position: relative;
    height: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 0 1rem 1rem 1.5rem;
  }

  .title-text {
    color: $smalt-blue-100;
    font-size: 1.125rem;
    line-height: 1.75rem;
    font-weight: 700;
    padding: 0 0.75rem 0 0.75rem;
    padding: 0 1rem 0 1rem;
    background-color: $orange;
    // --tw-bg-opacity: 0.9;
    border-radius: 0.25rem;
  }
}
