// **** Projects Screen

// spacer
.bl-2 {
  height: 4rem;
  background-color: $smalt-blue-800;
}

// diagonal line
#project-section:before {
  content: '';
  display: block;
  height: 50px;
  margin: -30px 0 0;
}

.projects-container {
  position: relative;
  background-color: $med-gray;

  //   width: 80%;

  .projects-container-inner {
    margin-left: auto;
    margin-right: auto;
    min-width: 95%;
    padding-bottom: 2rem;
    @media (min-width: 768px) {
      max-width: 72rem;
      min-width: 50%;
    }
  }

  .project-card-grid {
    // display: grid;
    // gap: 2rem;
    margin: 0rem 0.5rem 1rem 0.5rem;

    display: flex;
    flex-direction: column;
    // gap: 1rem;
    // align-items: center;
    .card {
      min-width: 85vw;
    }

    @media (min-width: 768px) {
      display: grid;
      gap: 2rem;
      margin: 1rem 1rem 0 1rem;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }

  .project-card {
    box-sizing: border-box;
    position: relative;
    // background-color: $light-off-white;
    background-color: $dark-gray;
    border-radius: 0.5rem;
    padding: 1.5rem;
    border-left-width: 12px;
    border-color: $smalt-blue-600;
    margin: 0.5rem;
    // width: 90%;

    @media (min-width: 768px) {
      padding: 2rem;
    }

    .proj-card-h3 {
      color: $smalt-blue-50;
      font-size: 1.875rem;
      line-height: 2.25rem;
      margin-bottom: 0.5rem;
    }

    .description-text {
      margin-top: 1.5rem;
      //   margin-bottom: 1.5rem;
      font-size: 1.125rem;
      line-height: 1.75rem;
      color: $smalt-blue-50;
      line-height: 1.625;
    }

    .project-card-links {
      display: flex;
      margin-top: 1.5rem;
      // margin: 1rem;
      // align-content: middle;

      .btn {
        min-height: 40px;
        // bottom: 1rem;
        margin-right: 1rem;
        background-color: rgba(255, 255, 255, 0.15);
        // opacity: 50%;
        // border: 1px #fff solid;
        font-size: medium;
        padding: 1rem;
        display: flex;
        vertical-align: bottom;

        &:hover {
          // text-decoration: underline;
          background-color: rgba(255, 255, 255, 0.25);
        }
      }

      .no-link {
        opacity: 0.4;
        &:hover {
          // text-decoration: underline;
          background-color: rgba(255, 255, 255, 0.15);
          transform: scale(1);
          cursor: default;
        }
      }

      .link-text {
        color: $smalt-blue-50;
        font-size: 1rem;
        align-self: center;

        @media (min-width: 768px) {
          font-size: 1.25rem;
        }
      }
    }
  }
}
