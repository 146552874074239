.btn {
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;
  color: #fff;
  border: none;
  border-radius: 5px;
}

.btn-outline {
  // position: absolute;
  bottom: 6rem;
  margin-top: 3rem;
  // background-color: rgba(69, 108, 115, 0.4);
  background-color: rgba(255, 255, 255, 0.13);

  // opacity: 50%;
  border: 1px #fff solid;
  font-size: large;
  box-shadow: 0 20px 20px -5px rgba(0, 0, 0, 0.15),
    0 10px 10px -25px rgba(0, 0, 0, 0.04);

  &:active {
    background-color: rgba(69, 108, 115, 0.4);
  }
}

.btn:hover {
  transform: scale(0.98);
  background-color: rgba(255, 255, 255, 0.1);
}

.home-buttons-main {
  display: flex;
  justify-content: center;

  .home-buttons-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding-top: 3rem;
    width: 65%;
    @media (min-width: 768px) {
      display: none;
    }
  }

  .landing-button {
    display: block;
    background-color: $smalt-blue-700;
    color: $smalt-blue-50;
    font-size: 1.125rem;
    font-weight: 1000;
    line-height: 1.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    // w-100?
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    border-radius: 0.25rem;
    text-align: center;
    opacity: 0.85;
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.3),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);

    &:hover {
      background-color: $smalt-blue-600;
    }

    &:active {
      background-color: smalt-blue-500;
    }

    &:visited {
      background-color: $smalt-blue-700;
    }
  }
}
